import { changeTimeLocalToUtc } from '../../../../../helper'
import { DATE_FORMAT } from '../../../../../utils/constants'

export class DealVanilla {
  constructor(form) {
    this.deal_type = form.deal_type
    this.client_side = form.client_side
    this.base_currency = form.base_currency.name
    this.counter_currency = form.counter_currency.name
    this.client = form.client
    this.market_maker = form.market_maker
    this.trade_date = changeTimeLocalToUtc(form.trade_date, DATE_FORMAT.REGULAR_DATE_TIME)
    this.quantity = form.quantity
    this.client_premium_amount = form.client_premium_amount
    this.market_maker_premium_amount = form.market_maker_premium_amount
    this.client_premium_currency = form.client_premium_currency.name
    this.market_maker_premium_currency = form.market_maker_premium_currency.name
    this.fixing_source = form.fixing_source
    this.fixing_date = form.fixing_date
    this.client_premium_date = form.client_premium_date
    this.market_maker_premium_date = form.market_maker_premium_date
    this.put_call = form.put_call
    this.settlement_date = form.settlement_date
    this.expiry = form.expiry
    this.strike = form.strike
    this.cut = form.cut
    this.client_option_payment_currency = form.client_option_payment_currency.name
    this.market_maker_option_payment_currency = form.market_maker_option_payment_currency.name
    this.option_exercise_style = form.option_exercise_style
    this.client_trader_name = form.client_trader_name
    this.market_maker_trader_name = form.market_maker_trader_name
    this.trade_origin = 'FXER'
  }
}
