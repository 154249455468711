import React from 'react'
import PropTypes from 'prop-types'
import PaperAutoComplete from '../../../../../PaperAutoComplete/PaperAutoComplete'
import { AutocompleteStyle, InputBaseStyle, TextFieldModalStyle } from '../../../../ModalApp.style'
import FlagCurrency from '../../../../../FlagCurrency/FlagCurrency'
import CurrencyLogo from '../../../../../CurrencyLogo/CurrencyLogo'
import { Box, Typography } from '@mui/material'
import { Label } from '../../../../../styled/global.style'
import SelectWithArrow from '../../../../../SelectWithArrow/SelectWithArrow'
import { OptionContainer } from '../../Deal.style'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'

const VanillaDealFirstColumnMarketMakerSide = ({
  marketMakers,
  handelDateChange,
  form,
  handelFormChange,
  currencyData,
  currencyInitial
}) => {
  return (
    <>
      <Box width={'100%'}>
        <Label>Market Maker *</Label>
        {Array.isArray(marketMakers.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'market_maker'
              element = element ?? ''
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.market_maker}
            options={marketMakers.data.map(({ name }) => name)}
            renderInput={params => (
              <TextFieldModalStyle fullWidth {...params} placeholder={'Select CP'} />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>

      <Box width={'100%'}>
        <Label>Base Currency *</Label>
        {Array.isArray(currencyData.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'base_currency'
              element = element ?? currencyInitial
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.base_currency}
            disabled
            getOptionLabel={option => option.name}
            options={currencyData.data.filter(({ name }) => name !== form.counter_currency.name)}
            renderInput={params => (
              <TextFieldModalStyle {...params} fullWidth placeholder="Select Currency" />
            )}
            renderOption={(props, option) => (
              <OptionContainer {...props} key={option.id}>
                <FlagCurrency countryCode={option.code3} />
                <CurrencyLogo currencyCode={option.name} />
                <Typography style={{ marginLeft: 'auto' }}>{option.name} </Typography>
              </OptionContainer>
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>

      <Box width={'100%'}>
        <Label>Strike Price*</Label>
        <InputBaseStyle
          disabled
          value={form.strike}
          name="strike"
          onChange={handelFormChange}
          placeholder="0.00"
        />
      </Box>
      <Box width={'100%'}>
        <Label>Settlement Date *</Label>
        <TextFieldStyle
          type="date"
          variant="outlined"
          disabled
          value={form.settlement_date}
          onChange={event => handelDateChange('settlement_date', event.target.value)}
        />
      </Box>
      <Box width={'100%'}>
        <Label>Premium Amount*</Label>
        <InputBaseStyle
          value={form.market_maker_premium_amount}
          name="market_maker_premium_amount"
          onChange={handelFormChange}
          placeholder="0.00"
        />
      </Box>
      <Box width={'100%'}>
        <Label>Exercise Style *</Label>
        <SelectWithArrow
          disabled
          name="option_exercise_style"
          placeholder="Select Exercise Style"
          orderType={form.option_exercise_style}
          onChange={handelFormChange}
          items={[{ name: 'European' }, { name: 'American' }]}
        />
      </Box>
    </>
  )
}

export default VanillaDealFirstColumnMarketMakerSide

VanillaDealFirstColumnMarketMakerSide.propTypes = {
  marketMakers: PropTypes.object,
  currencyData: PropTypes.object,
  currencyInitial: PropTypes.object,
  traders: PropTypes.object,
  handelFormChange: PropTypes.func,
  handelDateChange: PropTypes.func,
  form: PropTypes.object
}
