import React from 'react'
import PropTypes from 'prop-types'
import { AvatarStyle } from './CurrencyLogo.style'
import { CURRENCY } from '../../utils/constants'
import XDRLogo from '../../assets/xdr-image.gif'
import SourceLogo from '../../assets/source.jpg'

const CurrencyLogo = ({ currencyCode }) => {
  switch (currencyCode) {
    case CURRENCY.XAG:
      return <AvatarStyle backgroundColor="#D7D7D7" />
    case CURRENCY.XDR:
      return <img src={XDRLogo} style={{ borderRadius: '3px', height: '16px', width: '22px' }} />
    case CURRENCY.XUA:
      return <AvatarStyle backgroundColor="#00A96A" />
    case CURRENCY.XSU:
      return <img src={SourceLogo} style={{ borderRadius: '3px', height: '16px', width: '22px' }} />
    case CURRENCY.XAU:
      return <AvatarStyle backgroundColor="#B1B1B1" />
    case CURRENCY.XPD:
      return <AvatarStyle backgroundColor="#E5B80B" />
    case CURRENCY.XPT:
      return <AvatarStyle backgroundColor="#B1B1B1" />
    default:
      return <></>
  }
}



CurrencyLogo.propTypes = { currencyCode: PropTypes.string }

export default CurrencyLogo
