import styled from '@emotion/styled'
import { ReactComponent as FilterIconTable } from '../../../../assets/filter icon.svg'
import { Stack, TextField } from '@mui/material'
import calendarImage from '../../../../assets/calendar.svg' // Adjust the path as needed

export const FilterIconTableStyle = styled(FilterIconTable)(({ theme }) => ({
  '& :hover': {
    cursor: 'pointer',
    fill: theme.palette.button.save.hover
  }
}))

export const StackStyle = styled(Stack)(({ theme }) => ({
  width: '300px'
}))

export const TextFieldStyle = styled(TextField)(({ theme, myerror }) => ({
  border: myerror === 'true' ? `1px solid ${theme.palette.button.delete.primary}` : 'none',
  borderRadius: '4px',
  width: '100%',
  '& input[type="date"]::-webkit-calendar-picker-indicator': {
    cursor: 'pointer',
    backgroundImage: `url(${calendarImage})`
  },
  '& input[type="date"]::-moz-calendar-picker-indicator': {
    cursor: 'pointer',
    backgroundImage: `url(${calendarImage})`
  },
  '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
    cursor: 'pointer',
    backgroundImage: `url(${calendarImage})`
  },
  '& input[type="datetime-local"]::-moz-calendar-picker-indicator': {
    cursor: 'pointer',
    backgroundImage: `url(${calendarImage})`
  }
}))
