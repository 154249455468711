import React from 'react'
import PropTypes from 'prop-types'
import { ModalBottomStyle } from '../../ModalApp.style'
import { getModalSubmitButton } from '../../../../helper'
import ModalCancelButton from '../ModalCancelButton/ModalCancelButton'
import { Box } from '@mui/material'

const ModalBottom = ({
  data,
  submitForm,
  disabled,
  children,
  justifyContent = 'flex-end',
  title
}) => {
  return (
    <ModalBottomStyle>
      {children}
      <Box
        style={{
          display: 'flex',
          width: '100%',
          gap: '16px',
          justifyContent: justifyContent,
          alignItems: 'center'
        }}
      >
        <ModalCancelButton variant="contained" />
        {getModalSubmitButton(data, submitForm, disabled, title)}
      </Box>
    </ModalBottomStyle>
  )
}

export default ModalBottom

ModalBottom.propTypes = {
  data: PropTypes.any,
  submitForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  justifyContent: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string
}
