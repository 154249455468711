import { Divider, styled, Typography } from '@mui/material'

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  textTransform: 'capitalize',
  fontFamily: 'Nunito',
  textAlign: 'center',
  fontSize: '18px',
  marginBottom: '24px'
}))

export const DividerStyle = styled(Divider)(({ theme }) => ({
  border: `1px solid ${theme.palette.separators.primary}`,
  marginBottom: '32px'
}))
