import React from 'react'
import PropTypes from "prop-types"
import { GridWidget, TypographyWidgetStyle } from '../../TradeBlotter.style'
import { Grid } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { ReactComponent as UnionWidget } from '../../../../assets/Union_widget.svg'
import { ReactComponent as Close } from '../../../../assets/close.svg'


const WidgetRates = ({
  columnsDefs2,
  data,
  getRowId2,
  defaultColDef2,
  handleRemoveWidget
}) => {
  return (
    <>
      <GridWidget>
        <TypographyWidgetStyle>Rates</TypographyWidgetStyle>
        <Grid>
          <UnionWidget style={{ marginTop: '12px' }} />
          <Close
            style={{ marginRight: '10px' }}
            onClick={() => handleRemoveWidget('Rates')}
          />
        </Grid>
      </GridWidget>

      <AgGridReact
        columnDefs={columnsDefs2}
        rowData={data}
        getRowId={getRowId2}
        defaultColDef={defaultColDef2}
      />

    </>
  )
}

export default WidgetRates

WidgetRates.propTypes = {
  columnsDefs2: PropTypes.array,
  data: PropTypes.array,
  getRowId2: PropTypes.func,
  defaultColDef2: PropTypes.object,
  handleRemoveWidget: PropTypes.func
}