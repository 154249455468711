import { changeTimeLocalToUtc } from "../../../../../helper"
import { DATE_FORMAT } from "../../../../../utils/constants"

export class DealSpot {
  constructor(form) {
    this.deal_type = form.deal_type
    this.client_side = form.client_side
    this.client_price = form.client_price
    this.market_maker_price = form.market_maker_price
    this.base_currency = form.base_currency.name
    this.counter_currency = form.counter_currency.name
    this.client = form.client
    this.market_maker = form.market_maker
    this.trade_date =  changeTimeLocalToUtc(form.trade_date,DATE_FORMAT.REGULAR_DATE_TIME)
    this.quantity = form.quantity
    this.client_value_date = form.client_value_date
    this.market_maker_value_date = form.market_maker_value_date
    this.trade_origin = 'FXER'
  }
}
