import React from 'react'
import styled from '@emotion/styled'
import { Autocomplete, Box, Grid, Paper, Switch, TextField, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export const ModalContent = styled(Paper)(({ theme }) => ({
  borderRadius: '12px,',
  background: theme.palette.modal.primary,
  paddingBottom: '24px',
  paddingTop: '24px'
}))

export const SearchIconAutoComplete = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.button.save.primary
}))

export const InputBaseStyle = styled(TextField)(({ theme, error, width = 'auto' }) => {
  let border = 'none'

  if (error) {
    border = `1px solid ${theme.palette.button.delete.primary}`
  }

  return {
    border,
    width: '100%',
    borderRadius: '5px',
    '& .MuiTextField-root': {
      height: '45px',
      '-webkit-flex-direction': 'row',
      backgroundColor: 'transparent !important',
      border: '1px solid black !important',
      borderRadius: '10px'
    },
    '& .MuiFormControl-root': {
      display: 'block',
      padding: 0
    }
  }
})

export const ModalBottomStyle = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  marginTop: '40px',
  justifyContent: 'space-between',
  alignItems: 'end',
  paddingBottom: '24px'
}))

export const FormError = styled(Typography)(({ theme }) => ({
  color: theme.palette.button.delete.primary,
  display: 'inline'
}))

export const ModalContainer = styled(Box)(() => ({
  paddingLeft: '32px',
  paddingRight: '32px'
}))

export const Container = styled(Grid)(() => ({
  marginTop: '24px',
  marginBottom: '24px',
  width: '100%'
}))

export const ContainerRow = styled(Box)(() => ({
  marginTop: '24px',
  marginBottom: '24px',
  display: 'flex',
  width: '100%'
}))

export const SubContainer = styled(Grid)(() => ({
  margin: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px'
}))

export const SubContainerRow = styled(Box)(() => ({
  margin: '20px',
  display: 'flex',
  gap: '10px'
}))

export const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  marginRight: '15px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))

export const AutocompleteStyle = styled(Autocomplete)(({ theme, error }) => {
  let border = 'none'
  if (error) {
    border = `1px solid ${theme.palette.button.delete.primary}`
  }

  return {
    margin: 0,
    backgroundColor: theme.palette.separators.primary,
    border,
    width: '100%',
    height: '45px',
    borderRadius: '4px',
    '& .Mui-disabled': {
      backgroundColor: theme.palette.card.primary
    },
    '& .Mui-disabled .MuiSvgIcon-root': {
      color: theme.palette.switch.unactive
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.button.save.primary
    },
    '& .MuiAutocomplete-clearIndicator': {
      color: theme.palette.button.save.primary
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent'
      },
      '&:hover:not(.Mui-disabled) fieldset': {
        borderColor: 'transparent' // Remove the hover border
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent' // Remove the focus border
      }
    },
    '& .MuiAutocomplete-popupIndicator': {
      '&:hover': {
        backgroundColor: 'transparent' // Remove hover background color for the indicator
      }
    }
  }
})

export const TextFieldModalStyle = styled(TextField)(({ theme, myerror }) => {
  let border = 'none'
  if (myerror === 'true') {
    border = `1px solid ${theme.palette.button.delete.primary}`
  }
  return {
    backgroundColor: '#39393C',
    overflowY: 'auto',
    borderRadius: '4px',
    '& .Mui-error': {
      border
    }
  }
})
