import React from 'react'
import PropTypes from 'prop-types'
import { ModalBottomStyle } from '../../../ModalApp.style'
import { getModalSubmitButton } from '../../../../../helper'
import { Box } from '@mui/material'
import { CancelButton } from '../../../../styled/global.style'

const ModalBottomVanillaDeal = ({
  data,
  submitForm,
  disabled,
  justifyContent = 'flex-end',
  title,
  handelClick
}) => {
  return (
    <ModalBottomStyle>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          gap: '16px',
          justifyContent: justifyContent,
          alignItems: 'center'
        }}
      >
        <CancelButton onClick={handelClick}>Back</CancelButton>
        {getModalSubmitButton(data, submitForm, disabled, title)}
      </Box>
    </ModalBottomStyle>
  )
}

export default ModalBottomVanillaDeal

ModalBottomVanillaDeal.propTypes = {
  data: PropTypes.any,
  submitForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  justifyContent: PropTypes.string,
  handelClick: PropTypes.func,
  title: PropTypes.string
}
