import React, { useState } from 'react'
import { Box } from '@mui/material'
import { DownloadIconSelectHover, DownloadIconSelectStyle } from './DealExcelDownload.style'
import { useDispatch, useSelector } from 'react-redux'
import { downlandExcelTrades } from '../../helper'

const DownloadComponent = prop => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const [hover, setHover] = useState(false)

  return (
    <Box onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {hover ? (
        <DownloadIconSelectHover
          onClick={() =>
            downlandExcelTrades(
              dispatch,
              token,
              Number(prop.data.id),
              {
                deal_report_date: prop.data.deal_report_date
              },
              prop.data.file
            )
          }
        />
      ) : (
        <DownloadIconSelectStyle />
      )}
    </Box>
  )
}

export const columnsDefs = [
  {
    headerName: 'Download',
    cellRenderer: DownloadComponent
  },
  {
    headerName: 'File',
    field: 'file',
    flex: 1
  },
  { headerName: 'PB', field: 'prime_broker', flex: 1 },
  { headerName: 'File Size', field: 'file_size', flex: 1 }
]

export const defaultColDef = {}
