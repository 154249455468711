import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  type: '',
  title: '',
  data: {},
  isOpen: false,
  getRequest: false
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.type = action.payload.type
      state.title = action.payload.title
      state.data = action.payload.data || {}
      state.action = action.payload.action
      state.isOpen = action.payload.isOpen
    },
    setGetRequest: (state, action) => {
      state.getRequest = action.payload
    },
    closeModal: state => {
      state.type = ''
      state.title = ''
      state.data = {}
      state.isOpen = false
    }
  }
})

export const { setModal, closeModal, setGetRequest } = modalSlice.actions

export default modalSlice.reducer
