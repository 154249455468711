import React, { useState } from 'react'
import {
  CancelButton,
  CardText,
  CardTitle,
  MyCard,
  SaveChangesButton
} from '../../components/styled/global.style'
import { Box, CardContent } from '@mui/material'
import { useDispatch } from 'react-redux'
import { InputBaseStyle } from '../../components/ModalApp/ModalApp.style'
import validator from 'validator'
import { ERRORS } from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { resetAuthByEmail } from '../../redux/slices/Auth/AuthSlice'

const ResetPassword = () => {
  const [form, setForm] = useState({ email: '' })
  const [error, setError] = useState({ email: '' })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const validateForm = () => {
    if (!validator.isEmail(form.email)) {
      setError({ email: ERRORS.email('email') })
      return false
    }
    return true
  }
  const handelBackButton = () => {
    navigate('/login')
  }

  const handelSubmit = async () => {
    if (validateForm()) {
      dispatch(resetAuthByEmail(form, navigate))
    }
  }

  const disabledButton = () => {
    return form.email === '' ? true : false
  }

  const handelChange = event => {
    const { name, value } = event.target
    setForm({ [name]: value })
  }

  return (
    <MyCard>
      <CardContent>
        <CardTitle>Reset your password</CardTitle>
        <Box style={{ marginTop: '40px', marginBottom: '32px' }}>
          <CardText>Please enter your email address to receive</CardText>
          <CardText>a link confirmation to reset your password.</CardText>
        </Box>
        <InputBaseStyle
          name="email"
          onChange={handelChange}
          type="email"
          autoComplete="off"
          value={form.email}
          placeholder="email@example.con"
          error={!!error.email}
        ></InputBaseStyle>
      </CardContent>
      <Box style={{ width: '100%', display: 'flex', gap: '45px', marginTop: '45px' }}>
        <CancelButton variant="contained" onClick={handelBackButton}>
          Back
        </CancelButton>
        <SaveChangesButton variant="contained" onClick={handelSubmit} disabled={disabledButton()}>
          Continue
        </SaveChangesButton>
      </Box>
    </MyCard>
  )
}

export default ResetPassword
