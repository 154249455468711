import React from 'react'
import PropTypes from 'prop-types'
import FlagCurrency from '../../../../../FlagCurrency/FlagCurrency'
import CurrencyLogo from '../../../../../CurrencyLogo/CurrencyLogo'
import { Box, Typography } from '@mui/material'
import {
  AutocompleteStyle,
  InputBaseStyle,
  SubContainer,
  TextFieldModalStyle
} from '../../../../ModalApp.style'
import PaperAutoComplete from '../../../../../PaperAutoComplete/PaperAutoComplete'
import { Label } from '../../../../../styled/global.style'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'
import { ButtonGroupStyle, OptionContainer, SideButton } from '../../Deal.style'

const SpotDealSecondColumn = ({
  currencyData,
  form,
  sides,
  marketMakers,
  handelFormChange,
  handelDateChange,
  currencyInitial
}) => {
  return (
    <SubContainer item sm={5}>
      <Box width={'100%'}>
        <Label>Side *</Label>
        {Array.isArray(sides.data) && (
          <ButtonGroupStyle variant="outlined">
            {sides.data.map(({ name }, id) => (
              <SideButton
                name="client_side"
                key={id}
                value={name}
                select={String(form.client_side === name)}
                onClick={handelFormChange}
              >
                {name}
              </SideButton>
            ))}
          </ButtonGroupStyle>
        )}
      </Box>
      <Box width={'100%'}>
        <Label>Counter Currency *</Label>
        {Array.isArray(currencyData.data) && (
          <AutocompleteStyle
            fullWidth
            onChange={(event, element) => {
              event.target.name = 'counter_currency'
              element = element ?? currencyInitial
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.counter_currency}
            getOptionLabel={option => option.name}
            options={currencyData.data.filter(({ name }) => name !== form.base_currency.name)}
            renderOption={(props, option) => (
              <OptionContainer {...props} key={option.id}>
                <FlagCurrency countryCode={option.code3} />
                <CurrencyLogo currencyCode={option.name} />
                <Typography>{option.name} </Typography>
              </OptionContainer>
            )}
            renderInput={params => (
              <TextFieldModalStyle fullWidth {...params} placeholder="Select Currency" />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>
      <Box width={'100%'}>
        <Label>Trade Date *</Label>
        <TextFieldStyle type="datetime-local" value={form.trade_date} disabled />
      </Box>
      <Box width={'100%'}>
        <Label>Market Value Date *</Label>
        <Box className="date-container">
          <TextFieldStyle
            type="date"
            variant="outlined"
            placeholder="dd-mm-yyyy"
            value={form.market_maker_value_date}
            onChange={event => handelDateChange('value_date', event.target.value)}
          />
        </Box>
      </Box>
      <Box width={'100%'}>
        <Label>Market Price *</Label>
        <InputBaseStyle
          value={form.market_maker_price}
          name="market_maker_price"
          onChange={handelFormChange}
          placeholder="0.0000"
        />
      </Box>
      <Box width={'100%'}>
        <Label>Market Maker *</Label>
        {Array.isArray(marketMakers.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'market_maker'
              element = element ?? ''
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.market_maker}
            options={marketMakers.data.map(({ name }) => name)}
            renderInput={params => (
              <TextFieldModalStyle fullWidth {...params} placeholder={'Select CP'} />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>
    </SubContainer>
  )
}

export default SpotDealSecondColumn

SpotDealSecondColumn.propTypes = {
  currencyData: PropTypes.object,
  marketMakers: PropTypes.object,
  sides: PropTypes.object,
  handelDateChange: PropTypes.func,
  form: PropTypes.object,
  currencyInitial: PropTypes.object,
  handelFormChange: PropTypes.func
}
