import React from 'react'
import PropTypes from 'prop-types'
import './ModalHeader.style.js'
import { DividerStyle, Title } from './ModalHeader.style.js'
import { MODAL_TYPE } from '../../../../utils/constants.js'
import { Box, IconButton } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg'
import { useDispatch } from 'react-redux'
import { closeModal } from '../../../../redux/slices/Modal/modalSlice.js'


const ModalHeader = ({ title, type, dividerStyle = { marginBottom: "24px" } }) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(closeModal())
  }

  switch (type) {
    case MODAL_TYPE.DEAL_REPORT_STATUS:
      {
        const [firstTitle, dealId] = title.split(" ")
        return <>
          <Box style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Box style={{ display: "flex", gap: "5px" }}>
              <Title >{firstTitle}</Title>
              <Title style={{ color: "#A4A4FF" }}>{dealId}</Title>
            </Box>
            <IconButton style={{ marginLeft: "12px" }} onClick={handleClick}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DividerStyle style={dividerStyle}></DividerStyle>
        </>
      }
    default:
      return <>
        <Title>{title}</Title>
        <DividerStyle style={dividerStyle}></DividerStyle>
      </>

  }
}

ModalHeader.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  dividerStyle: PropTypes.object
}

export default ModalHeader
