import React from 'react'
import PropTypes from 'prop-types'
import { IconButtonStyle } from '../../../TradeBlotter/TradeBlotter.style'
import {
  FilterIconTableStyle,
  StackStyle,
  TextFieldStyle
} from '../../../TradeBlotter/components/FilterTable/FilterTable.style'
import { Label } from '../../../../components/styled/global.style'
import { StyledMenu } from './MenuFilter.style'

const MenuFilter = ({ handelFilters, filters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButtonStyle press={String(open)} onClick={handleClick}>
        <FilterIconTableStyle aria-expanded={open ? 'true' : undefined} />
      </IconButtonStyle>
      <StyledMenu
        style={{
          padding: '20px',
          display: 'flex',
          gap: '20px',
          flexDirection: 'column'
        }}
        anchorEl={anchorEl}
        aria-expanded={open ? 'true' : undefined}
        open={open}
        onClose={handleClose}
      >
        <StackStyle>
          <Label>Date</Label>
          <TextFieldStyle
            type="date"
            variant="outlined"
            value={filters.date}
            name={'date'}
            style={{ background: 'black !important' }}
            onChange={handelFilters}
          />
        </StackStyle>
      </StyledMenu>
    </>
  )
}

export default MenuFilter

MenuFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  handelFilters: PropTypes.func.isRequired
}
