import React from 'react'
import PropTypes from 'prop-types'
import { GridWidget, TypographyWidgetStyle } from '../../TradeBlotter.style'
import { Grid } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { SIGN } from '../../../../utils/constants'
import Pagination from '../../../../components/Pagination/Pagination'
import { ReactComponent as UnionWidget } from '../../../../assets/Union_widget.svg'
import { ReactComponent as Close } from '../../../../assets/close.svg'

const WidgetTradeBlotter = ({
  handleRemoveWidget,
  gridRef,
  columnsDefs,
  detailCellRenderer,
  defaultColDef,
  getRowStyle,
  tradeBlotterResponse,
  getRowId,
  onBodyScroll,
  onRowClicked,
  onRowDoubleClicked,
  getContextMenuItems,
  isRowMaster,
  handelPagination,
  page,
  disabled
}) => {
  return (
    <>
      <GridWidget>
        <TypographyWidgetStyle>Trade Blotter</TypographyWidgetStyle>
        <Grid>
          <UnionWidget style={{ marginTop: '12px' }} />
          <Close
            style={{ marginRight: '10px' }}
            onClick={() => handleRemoveWidget('Trade Blotter')}
          />
        </Grid>
      </GridWidget>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnsDefs}
        masterDetail={true}
        detailCellRenderer={detailCellRenderer}
        defaultColDef={defaultColDef}
        suppressRowClickSelection={true}
        getRowStyle={getRowStyle}
        rowData={tradeBlotterResponse.data}
        rowMultiSelectWithClick={true}
        suppressScrollOnNewData={true}
        detailRowAutoHeight={true}
        getRowId={getRowId}
        onBodyScroll={onBodyScroll}
        onRowClicked={onRowClicked}
        onRowDoubleClicked={onRowDoubleClicked}
        getContextMenuItems={getContextMenuItems}
        groupUseEntireRow={true}
        isRowMaster={isRowMaster}
        rowSelection={'multiple'}
      />
      <Pagination
        handelNextPagination={() => handelPagination(SIGN.NEXT)}
        handelPrevPagination={() => handelPagination(SIGN.PREV)}
        page={page}
        disabledPrev={disabled.prev}
        disabledNext={disabled.next}
      />
    </>
  )
}

export default WidgetTradeBlotter

WidgetTradeBlotter.propTypes = {
  handleRemoveWidget: PropTypes.func,
  gridRef: PropTypes.any,
  columnsDefs: PropTypes.array,
  detailCellRenderer: PropTypes.func,
  defaultColDef: PropTypes.object,
  getRowStyle: PropTypes.func,
  tradeBlotterResponse: PropTypes.any,
  getRowId: PropTypes.func,
  onBodyScroll: PropTypes.func,
  onRowClicked: PropTypes.func,
  onRowDoubleClicked: PropTypes.func,
  getContextMenuItems: PropTypes.func,
  isRowMaster: PropTypes.any,
  handelPagination: PropTypes.func,
  page: PropTypes.number,
  disabled: PropTypes.object
}
