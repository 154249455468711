import React from 'react'
import PropTypes from 'prop-types'
import FlagCurrency from '../../../../../FlagCurrency/FlagCurrency'
import CurrencyLogo from '../../../../../CurrencyLogo/CurrencyLogo'
import { Box, Typography } from '@mui/material'
import { AutocompleteStyle, InputBaseStyle, TextFieldModalStyle } from '../../../../ModalApp.style'
import PaperAutoComplete from '../../../../../PaperAutoComplete/PaperAutoComplete'
import { Label } from '../../../../../styled/global.style'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'
import { OptionContainer } from '../../Deal.style'

const SpotDealFirstColumn = ({
  currencyData,
  clients,
  form,
  handelFormChange,
  handelDateChange,
  currencyInitial
}) => {
  return (
    <>
      <Box width={'100%'}>
        <Label>Base Currency *</Label>
        {Array.isArray(currencyData.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'base_currency'
              element = element ?? currencyInitial
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.base_currency}
            getOptionLabel={option => option.name}
            options={currencyData.data.filter(({ name }) => name !== form.counter_currency.name)}
            renderInput={params => (
              <TextFieldModalStyle {...params} fullWidth placeholder="Select Currency" />
            )}
            renderOption={(props, option) => (
              <OptionContainer {...props} key={option.id}>
                <FlagCurrency countryCode={option.code3} />
                <CurrencyLogo currencyCode={option.name} />
                <Typography style={{ marginLeft: 'auto' }}>{option.name} </Typography>
              </OptionContainer>
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>
      <Box width={'100%'}>
        <Label>Quantity *</Label>
        <InputBaseStyle
          value={form.quantity}
          name="quantity"
          onChange={handelFormChange}
          placeholder="0.00"
        />
      </Box>
      <Box width={'100%'} className="date-container">
        <Label>Client Value Date *</Label>
        <TextFieldStyle
          type="date" value={form.client_value_date} placeholder='dd-mm-yyyy' onChange={handelDateChange}/>
      </Box>
      <Box width={'100%'}>
        <Label>Client Price *</Label>
        <InputBaseStyle
          value={form.client_price}
          name="client_price"
          onChange={handelFormChange}
          placeholder="0.0000"
        />
      </Box>
      <Box width={'100%'}>
        <Label>Client *</Label>
        {Array.isArray(clients.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'client'
              element = element ?? ''
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.client}
            options={clients.data.map(({ name }) => name)}
            renderInput={params => (
              <TextFieldModalStyle fullWidth {...params} placeholder={'Select CP'} />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>
    </>
  )
}

export default SpotDealFirstColumn

SpotDealFirstColumn.propTypes = {
  currencyData: PropTypes.object,
  clients: PropTypes.object,
  form: PropTypes.object,
  currencyInitial: PropTypes.object,
  handelFormChange: PropTypes.func,
  handelDateChange:PropTypes.func
}
