import { createSlice } from '@reduxjs/toolkit'




const initialState = {
  widgets: [],
}

const widgets = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    setWidgets: (state, action) => {
      state.widgets = action.payload
    },
  }
})


export const { setWidgets } = widgets.actions

export default widgets.reducer
