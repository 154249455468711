import React, { useCallback, useRef, useState } from 'react'
import Status from '../components/Status/Status'
import {
  CheckboxTradeBlotter,
  CopyComponentStyle,
  DownloadComponentStyle,
  InfoDealStatusComponentStyle,
  TextQuantity
} from '../TradeBlotter.style'
import { Box, FormControlLabel, Typography } from '@mui/material'
import {
  leToolRoutes,
  MODAL_TITLE,
  MODAL_TYPE,
  routes,
  STAGING_STATUS,
  TRADE_BLOTTER_STATUS
} from '../../../utils/constants'
import CollapseData from '../components/CollapseData/CollapseData'
import CurrencyLogo from '../../../components/CurrencyLogo/CurrencyLogo'
import FlagCurrency from '../../../components/FlagCurrency/FlagCurrency'
import { copyId, downlandExcelTrades, numberFormat } from '../../../helper'
import MenuItem from '../components/MenuItem/MenuItem'
import { CheckboxStyle } from '../../../components/styled/global.style'
import { ReactComponent as DeselectedCheckBox } from '../../../assets/deSelectCheckbox.svg'
import { setModal } from '../../../redux/slices/Modal/modalSlice'
import axios from 'axios'
import { handelNotifications } from '../../../redux/slices/Notification/notificationSlice'

const useTradeBlotterAgGridSetting = ({
  dealTradesGroup,
  setDealTradesGroup,
  dealTradesAllocateGroup,
  setDealTradesAllocatedGroup,
  dealsGiveUpGroup,
  setDealsGiveUpGroup,
  tradesGroup,
  setTradesGroup,
  dealsGroup,
  setDealsGroup,
  cleanCheckBoxState,
  selected,
  setSelected,
  dispatch,
  token,
  pendingData,
  tradeBlotterResponse,
  gridScrollHight,
  setTradeBlotterResponse,
  setPendingData
}) => {
  const [lock, setLock] = useState(false)
  const queryParams = useRef({ prime_broker: '' })
  const detailCellRenderer = useCallback(
    params => {
      return (
        <CollapseData
          data={params.data}
          handelTradeDeal={handelTradeDeal}
          handelTradeDealChecked={handelTradeDealChecked}
          dealTradesGroup={dealTradesGroup}
          params={queryParams}
        />
      )
    },
    [dealTradesGroup, selected, queryParams]
  )

  const handelTradeDeal = useCallback(
    function (trade) {
      const copyDealTradesGroup = JSON.parse(JSON.stringify(dealTradesGroup))
      const copyDealTradesAllocateGroup = JSON.parse(JSON.stringify(dealTradesAllocateGroup))
      const id = Number(trade.id)
      if (!copyDealTradesGroup[id]) {
        copyDealTradesGroup[id] = trade
        setDealTradesGroup(copyDealTradesGroup)
        setSelected(selected + 1)
      } else {
        setSelected(selected - 1)
        delete copyDealTradesGroup[id]
        setDealTradesGroup(copyDealTradesGroup)
      }
      if (!trade.prime_broker) {
        if (!copyDealTradesAllocateGroup[id]) {
          copyDealTradesAllocateGroup[id] = trade
          setDealTradesAllocatedGroup(copyDealTradesAllocateGroup)
        } else {
          setSelected(selected - 1)
          delete copyDealTradesAllocateGroup[id]
          setDealTradesAllocatedGroup(copyDealTradesAllocateGroup)
        }
      }
    },
    [dealTradesGroup, dealTradesAllocateGroup, selected]
  )

  const handelTradeDealChecked = useCallback(
    function (params) {
      const id = Number(params.id)
      return id in dealTradesGroup || id in dealTradesAllocateGroup
    },
    [dealTradesGroup, dealTradesAllocateGroup]
  )

  const isRowMaster = useCallback(dataItem => {
    return dataItem ? Array.isArray(dataItem.trades) : false
  }, [])

  const CurrencyPairCell = prop => {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          width: '100%',
          height: '100%'
        }}
      >
        <Typography>{prop.data.base_currency}</Typography>
        <FlagCurrency countryCode={prop.data.base_currency_country} />
        <CurrencyLogo currencyCode={prop.data.base_currency} />
        <Typography>/</Typography>
        <Typography>{prop.data.counter_currency}</Typography>
        <FlagCurrency countryCode={prop.data.counter_currency_country} />
        <CurrencyLogo currencyCode={prop.data.counter_currency} />
      </Box>
    )
  }
  const getRowId = useCallback(function (params) {
    if (params.data.deal_id === null) {
      return String(params.data.id).concat('T')
    }
    return String(params.data.id).concat('D') ?? null
  }, [])

  const ClientQuantity = prop => {
    const { client_quantity } = prop.data
    return <TextQuantity quantity={client_quantity}>{numberFormat(client_quantity)}</TextQuantity>
  }

  const ValueDate = prop => {
    const { value_date, settlement_date } = prop.data
    return <TextQuantity>{settlement_date || value_date}</TextQuantity>
  }

  const handelChecked = useCallback(
    params => {
      if (Array.isArray(params.trades)) {
        return Number(params.id) in dealsGroup
      } else {
        return Number(params.id) in tradesGroup
      }
    },
    [tradesGroup, dealsGroup]
  )
  const MarketMakerQuantity = prop => {
    const { market_maker_quantity } = prop.data
    return (
      <TextQuantity quantity={market_maker_quantity}>
        {numberFormat(prop.data.market_maker_quantity)}
      </TextQuantity>
    )
  }

  const MyHeaderComponent = () => {
    const cleanCheckBox = () => {
      if (selected !== 0) {
        cleanCheckBoxState()
      }
    }

    return (
      <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <FormControlLabel
          control={
            <CheckboxStyle
              icon={<DeselectedCheckBox fontSize="small" />}
              checkedIcon={<DeselectedCheckBox fontSize="small" />}
            />
          }
          onClick={cleanCheckBox}
          label={`(${selected})`}
        />
        <Typography className="ag-header-cell-text" style={{ textAlign: 'center', width: '100%' }}>
          Id
        </Typography>
      </Box>
    )
  }

  const defaultColDef = {
    resizable: false,
    suppressMovable: true,
    cellClass: 'suppress-movable-col'
  }

  const columnsDefs = [
    {
      headerName: 'Id',
      field: 'id',
      headerComponent: MyHeaderComponent,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: params => {
          return (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
              className="deal-container"
            >
              <Status
                status={params.data.status}
                allocationStatus={params.data.allocation_status}
                stagingStatus={params.data.staging_status}
                isDeal={Array.isArray(params.data.trades)}
              />
              <CheckboxTradeBlotter
                checked={handelChecked(params.data)}
                onChange={() => handelChangeGroup(params.data)}
              />
              {params.data.id}
            </Box>
          )
        }
      }
    },
    { headerName: 'Type', field: 'type' },
    { headerName: 'Client', field: 'client' },
    { headerName: 'Market Maker', field: 'market_maker' },
    {
      headerName: 'Buy/Sell',
      field: 'side',
      valueFormatter: params => {
        switch (params.data.side) {
          case 'BUY':
            return 'SELL'
          case 'SELL':
            return 'BUY'
        }
      }
    },
    { headerName: 'Client Quantity', field: 'client_quantity', cellRenderer: ClientQuantity },
    {
      headerName: 'Market Maker Quantity',
      field: 'market_maker_quantity',
      cellRenderer: MarketMakerQuantity
    },
    {
      headerName: 'Symbol',
      field: 'symbol',
      cellRenderer: CurrencyPairCell
    },
    {
      headerName: 'Premium',
      field: 'premium_amount'
    },
    {
      headerName: 'Premium Currency',
      field: 'premium_currency'
    },
    {
      headerName: 'Premium Date',
      field: 'premium_date'
    },
    {
      headerName: 'Client Price',
      field: 'client_price',
      valueFormatter: params => {
        return numberFormat(params.data.client_price, 6)
      }
    },
    {
      headerName: 'Market Maker Price',
      field: 'market_maker_price',
      valueFormatter: params => {
        return numberFormat(params.data.market_maker_price, 6)
      }
    },
    {
      headerName: 'P&L USD',
      field: 'pnl_usd',
      valueFormatter: params => {
        return numberFormat(params.data.pnl_usd)
      }
    },
    {
      headerName: 'Trade Date',
      field: 'trade_date',
      comparator: (valueA, valueB) => new Date(valueA).getTime() - new Date(valueB).getTime()
    },
    {
      headerName: 'Value Date',
      cellRenderer: ValueDate,
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const time1 = nodeA.data.value_date ?? nodeA.data.settlement_date
        const time2 = nodeB.data.value_date ?? nodeB.data.settlement_date
        return new Date(time1).getTime() - new Date(time2).getTime()
      }
    },
    { headerName: 'Expiry', field: 'expiry' },
    { headerName: 'Cut', field: 'cut' },
    { headerName: 'Prime Broker', field: 'prime_broker' },
    { headerName: 'Put Call', field: 'put_call' },
    { headerName: 'Direction', field: 'direction' }
  ]

  const onRowClicked = useCallback(
    params => {
      const id = Number(params.data.id)
      // if trade not selected
      if (!params.event.target.parentNode.querySelector('input')?.checked) {
        if (!Array.isArray(params.data.trades)) {
          const copyTradesGroup = JSON.parse(JSON.stringify(tradesGroup))
          copyTradesGroup[id] = params.data
          setSelected(selected + 1)
          setTradesGroup(copyTradesGroup)
        } else if (Array.isArray(params.data.trades) && !params.node.parent?.expanded) {
          const copyDealsGroup = JSON.parse(JSON.stringify(dealsGroup))
          const copyDealsGiveUpGroup = JSON.parse(JSON.stringify(dealsGiveUpGroup))
          copyDealsGroup[id] = params.data
          setSelected(selected + 1)
          if (params.data.staging_status === STAGING_STATUS.STAGED) {
            copyDealsGiveUpGroup[id] = params.data
            setDealsGiveUpGroup(copyDealsGiveUpGroup)
          }
          setDealsGroup(copyDealsGroup)
        }
      } else if (params.event.target.parentNode.querySelector('input')?.checked) {
        if (!Array.isArray(params.data.trades)) {
          const copyTradesGroup = JSON.parse(JSON.stringify(tradesGroup))
          delete copyTradesGroup[id]
          setSelected(selected - 1)
          setTradesGroup(copyTradesGroup)
        } else if (Array.isArray(params.data.trades) && !params.node.parent?.expanded) {
          const copyDealsGroup = JSON.parse(JSON.stringify(dealsGroup))
          const copyDealsGiveUpGroup = JSON.parse(JSON.stringify(dealsGiveUpGroup))
          delete copyDealsGroup[id]
          if (copyDealsGiveUpGroup[id]) {
            delete copyDealsGiveUpGroup[id]
            setDealsGiveUpGroup(copyDealsGiveUpGroup)
          }
          setSelected(selected - 1)
          setDealsGroup(copyDealsGroup)
        }
      }
    },
    [tradesGroup, dealsGroup, selected]
  )
  const handelChangeGroup = useCallback(
    params => {
      const tradesGroupCopy = JSON.parse(JSON.stringify(tradesGroup))
      const dealsGroupCopy = JSON.parse(JSON.stringify(dealsGroup))
      const dealsGiveUpGroupCopy = JSON.parse(JSON.stringify(dealsGiveUpGroup))

      const id = Number(params.id)
      if (Array.isArray(params.trades) && !dealsGroup[id]) {
        dealsGroupCopy[id] = params
        setDealsGroup(dealsGroupCopy)
        if (params.staging_status === STAGING_STATUS.STAGED) {
          dealsGiveUpGroupCopy[id] = params
          setDealsGiveUpGroup(dealsGiveUpGroupCopy)
        }
      } else if (!Array.isArray(params.trades) && tradesGroup[id]) {
        delete tradesGroupCopy[id]
        setTradesGroup(tradesGroupCopy)
      } else if (!Array.isArray(params.trades) && !tradesGroup[id]) {
        tradesGroupCopy[id] = params
        setTradesGroup(tradesGroupCopy)
      } else if (Array.isArray(params.trades) && dealsGroup[id]) {
        delete dealsGroupCopy[id]
        setDealsGroup(dealsGroupCopy)
        if (params.staging_status === STAGING_STATUS.STAGED) {
          delete dealsGiveUpGroupCopy[id]
          setDealsGiveUpGroup(dealsGiveUpGroupCopy)
        }
      }
    },
    [tradesGroup, dealsGroup]
  )

  const getRowStyle = useCallback(params => {
    if (
      params.data.status === TRADE_BLOTTER_STATUS.WARNING ||
      params.data.allocation_status === TRADE_BLOTTER_STATUS.UNALLOCATED ||
      params.data.staging_status === TRADE_BLOTTER_STATUS.STAGED
    ) {
      return { background: '#242432' }
    }
    if (params.data.status === TRADE_BLOTTER_STATUS.NEW) {
      params.data.status = TRADE_BLOTTER_STATUS.NEW_END
      return { background: '#30F0D626', animation: 'fadeOut ease-out 3s forwards' }
    }
    if (params.data.status === TRADE_BLOTTER_STATUS.UNION) {
      return { background: '#3a2f15' }
    }
  }, [])

  const handelModalDealReportStatus = dealId => {
    dispatch(
      setModal({
        type: MODAL_TYPE.DEAL_REPORT_STATUS,
        title: MODAL_TITLE.DEAL_REPORT_STATUS.concat(` ${dealId}`),
        data: dealId,
        isOpen: true
      })
    )
  }

  const getContextMenuItems = useCallback(
    params => {
      const handelCsvDeal = async (preferTitle, queryParams) => {
        const dealId = Number(params.node.data.id)
        const trades = params.node.data.trades.map(({ id }) => Number(id))
        const body = {
          deal_id: dealId,
          trade_ids: trades
        }
        const url = `${process.env.REACT_APP_FX_LETOOL_URL}${leToolRoutes.getFileNames}`
        try {
          const response = await axios.post(url, body)
          const dealFilenames = response.data.filenames
          const title = dealFilenames.length === 1 ? dealFilenames[0] : preferTitle

          await downlandExcelTrades(
            dispatch,
            token,
            dealId,
            queryParams,
            title,
            routes.dealReportTradeReportCsv
          )
          return true
        } catch (error) {
          dispatch(
            handelNotifications({
              id: Date.now(),
              type: 'error',
              message: 'please try again'
            })
          )
        }
      }

      const showTrade =
        params.node &&
        params.node.data.deal_id === null &&
        params.node.data.lucera_trade_id !== null

      const showCollapse =
        params.node && params.node.detail && Array.isArray(params.node.data.trades)
      if (showCollapse) {
        return [
          {
            ...{
              menuItem: () => (
                <MenuItem
                  name={'Download Trade PB'}
                  onMenuClick={async () => {
                    const dealId = params.node.data.id
                    const title = `makor_fxer_${Number(dealId)}_${queryParams.current.prime_broker}`
                    await handelCsvDeal(title, queryParams.current)
                  }}
                  dealId={params.node.data.id}
                  icon={<DownloadComponentStyle />}
                  disabled={false}
                />
              )
            }
          }
        ]
      }

      if (showTrade) {
        return [
          {
            ...{
              menuItem: () => (
                <MenuItem
                  name={'Copy Trade'}
                  onMenuClick={() => copyId(dispatch, params.node.data.lucera_trade_id, 'trade')}
                  icon={<CopyComponentStyle />}
                  disabled={false}
                />
              )
            }
          }
        ]
      }

      const showDownload =
        params.node && Array.isArray(params.node.data.trades) && params.node.master
      if (!showDownload) {
        return false
      }
      const disabledDownload = params.node.data.staging_status === STAGING_STATUS.STAGED

      const luceraTradesId = () => {
        if (Array.isArray(params.node.data.trades)) {
          return Array.from(
            new Set(params.node.data.trades.map(({ lucera_trade_id }) => lucera_trade_id))
          )
        } else {
          return params.node.data.lucera_deal_id
        }
      }
      if (showDownload) {
        return [
          {
            ...(showDownload
              ? {
                  menuItem: () => (
                    <MenuItem
                      name={'Download Deal Report'}
                      onMenuClick={() => handelCsvDeal(`makor_fxer_${Number(params.node.data.id)}`)}
                      dealId={Number(params.node.data.id)}
                      disabled={disabledDownload}
                      icon={<DownloadComponentStyle />}
                    />
                  )
                }
              : [])
          },
          {
            ...{
              menuItem: () => (
                <MenuItem
                  name={'Copy Deal'}
                  onMenuClick={() => copyId(dispatch, luceraTradesId().join(','))}
                  icon={<CopyComponentStyle />}
                  disabled={
                    (Array.isArray(params.node.data.trades) && !params.node.data.lucera_deal) ||
                    (params.node.data.deal_id === null && !params.node.data.lucera_deal_id)
                  }
                />
              )
            }
          },
          {
            ...{
              menuItem: () => (
                <MenuItem
                  name={'Status'}
                  onMenuClick={() => handelModalDealReportStatus(params.node.data.id)}
                  icon={<InfoDealStatusComponentStyle />}
                  disabled={false}
                />
              )
            }
          }
        ]
      }
    },
    [queryParams]
  )

  const onRowDoubleClicked = useCallback(params => {
    const rowNode = params.node
    if (Array.isArray(params.data.trades)) {
      rowNode.setExpanded(!rowNode.expanded)
    }
  }, [])

  const onBodyScroll = useCallback(
    params => {
      gridScrollHight.current.top = params.top
      if (pendingData.status !== null && gridScrollHight.current.top < 200 && lock === false) {
        setLock(true)
        setTradeBlotterResponse(pendingData)
        setPendingData(() => {
          setLock(false)
          return { status: null, data: [] }
        })
      }
    },
    [pendingData, tradeBlotterResponse, gridScrollHight, lock]
  )

  return {
    onRowClicked,
    onBodyScroll,
    columnsDefs,
    defaultColDef,
    isRowMaster,
    getRowId,
    getContextMenuItems,
    detailCellRenderer,
    getRowStyle,
    onRowDoubleClicked
  }
}

export default useTradeBlotterAgGridSetting
