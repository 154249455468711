import { Box, CircularProgress, Divider, Stack, styled, Typography } from '@mui/material'

export const TitleReportStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.modal_report_title.primary,
    marginLeft: "24px"
}))

export const ContainerDealReportSection = styled(Box)(() => ({
    width: "100%"
}))

export const ContainerDealReportSectionRow = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "24px",
    marginLeft: "36px",
    marginBottom: "11px"
}))

export const ContainerDealReport = styled(Stack)(({ theme }) => ({
    minWidth: "300px",
    maxHeight: "466px",
    overflowY: "auto",
    [theme.breakpoints.down("md")]: {
        height: "100dvh",
        width: "100dvw",
        maxHeight: "100dvh",
    }
}))

export const DividerDealReport = styled(Divider)(({ theme }) => ({
    border: `1px solid ${theme.palette.separators.primary}`,
    marginBottom: "12px",
}))

export const TypographyDateStyle = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontStyle: "italic",
    color: `${theme.palette.button.disable} !important`
}))

export const TypographyPrimeBrokerStyle = styled(Typography)(({ theme }) => ({
    borderRadius: "2px",
    height: "12px",
    padding: "6px 8px",
    background: `${theme.palette.button.cancel.primary}`,
    color: `${theme.palette.tabs.backgroundColor.primary} !important`,
    textAlign: "center",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Nunito",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
}))

export const SpinnerDealReportLoader = styled(CircularProgress)(({ theme }) => ({
    height:"16px !important",
    width:"16px !important",
    color:theme.palette.button.text.primary
}))