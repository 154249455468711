export const toolBarTabs = [
  'Clients',
  'Market makers',
  'Prime Brokers',
  'Fixing Sources',
  'Cuts',
  'Currency Pairs',
  'Users',
  'Entities'
]
