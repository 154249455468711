/* eslint-disable  */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, IconButton } from '@mui/material'
import useAxios from '../../../../hooks/useAxios.js'
import { DATE_FORMAT, DEAL_TYPE, routes } from '../../../../utils/constants.js'
import SelectWithArrow from '../../../SelectWithArrow/SelectWithArrow.js'
import { Container, ModalContainer, SubContainer } from '../../ModalApp.style.js'
import ModalBottom from '../../components/ModalBottom/ModalBottom.js'
import { changeTimeLocalToUtc, checkSomeLoader } from '../../../../helper/index.js'
import Loader from '../../../Loader/Loader.js'
import dayjs from 'dayjs'
import { webSocketWorkerEvent } from '../../../../services/index.js'
import { useDispatch } from 'react-redux'
import { handelNotifications } from '../../../../redux/slices/Notification/notificationSlice.js'
import { closeModal } from '../../../../redux/slices/Modal/modalSlice.js'
import CurrencyLogo from '../../../CurrencyLogo/CurrencyLogo.js'
import { Label } from '../../../styled/global.style.js'
import { DealSpot } from './classes/DealSpot.js'
import { DealVanilla } from './classes/DealVanilla.js'
import SpotDealFirstColumn from './components/SpotDeal/SpotDealFirstColumn.js'
import SpotDealSecondColumn from './components/SpotDeal/SpotDealSecondColumn.js'
import VanillaDealFirstColumnClientSide from './components/VanillaDealClientSide/VanillaDealFirstColumnClientSide.js'
import VanillaDealSecondColumnClientSide from './components/VanillaDealClientSide/VanillaDealSecondColumnClientSide.js'
import VanillaDealThirdColumnClientSide from './components/VanillaDealClientSide/VanillaDealThirdColumnClientSide.js'
import VanillaDealFirstColumnMarketMakerSide from './components/VanillaDealMarketMakerSide/VanillaDealFirstColumnMarketMakerSide.js'
import VanillaDealSecondColumnMarketMakerSide from './components/VanillaDealMarketMakerSide/VanillaDealSecondColumnMarketMakerSide.js'
import VanillaDealThirdColumnMarketMakerSide from './components/VanillaDealMarketMakerSide/VanillaDealThirdColumnMarketMakerSide.js'
import { ClientDealVanillaForm } from './classes/ClientDealVanillaForm.js'
import ModalBottomVanillaDeal from '../../components/ModalBottom/ModalBottomVanillaDeal/ModalBottomVanillaDeal.js'
const Deal = ({ data }) => {
  const [page, setPage] = useState(false)
  const dispatch = useDispatch()
  let loaderFirstTime = useRef(true).current
  useEffect(() => {
    const handelMessage = message => {
      if (message.data.type === 'create_deal' && message.data.status === 'ok') {
        dispatch(
          handelNotifications({
            id: Date.now(),
            type: 'success',
            message: 'Deal Made Successfully'
          })
        )
        dispatch(closeModal())
      }
    }

    webSocketWorkerEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerEvent])

  const currencyInitial = { id: 0, name: '', code3: '' }

  const request1 = {
    endPoint: routes.currency
  }
  const request2 = {
    endPoint: routes.side
  }
  const request3 = {
    endPoint: routes.client
  }
  const request4 = {
    endPoint: routes.marketMaker
  }
  const request5 = {
    endPoint: routes.dealTypes
  }

  const request6 = {
    endPoint: routes.cut
  }

  const request7 = {
    endPoint: routes.fixingSource
  }

  const request8 = {
    endPoint: routes.trader
  }

  const { response: currencyData, loading: currencyLoader } = useAxios(request1)
  const { response: sides, loading: sidesLoader } = useAxios(request2)
  const { response: clients, loading: clientsLoader } = useAxios(request3)
  const { response: marketMakers, loading: marketMakersLoader } = useAxios(request4)
  const { response: dealTypes, loading: dealTypesLoader } = useAxios(request5)
  const { response: cut, loading: cutLoader } = useAxios(request6)
  const { response: fixingSource, loading: fixingSourceLoader } = useAxios(request7)
  const { response: traders, loading: tradersLoader } = useAxios(request8)

  const loaderList = [
    currencyLoader,
    sidesLoader,
    clientsLoader,
    marketMakersLoader,
    dealTypesLoader,
    cutLoader,
    fixingSourceLoader,
    tradersLoader
  ]

  const [form, setForm] = useState({
    deal_type: data.deal_type ?? '',
    client_side: '',
    base_currency: { id: 0, name: '', code3: '' },
    counter_currency: { id: 0, name: '', code3: '' },
    quantity: '',
    client_price: '',
    market_maker_price: '',
    trade_date: dayjs().format(DATE_FORMAT.REGULAR_DATE_TIME),
    client_value_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    market_maker_value_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    client: '',
    market_maker: '',
    trade_origin: 'FXER',
    client_premium_amount: '',
    market_maker_premium_amount: '',
    client_premium_currency: { id: 0, name: '', code3: '' },
    market_maker_premium_currency: { id: 0, name: '', code3: '' },
    client_premium_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    market_maker_premium_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    settlement_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    expiry: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    put_call: '',
    cut: '',
    strike: '',
    client_option_payment_currency: { id: 0, name: '', code3: '' },
    market_maker_option_payment_currency: { id: 0, name: '', code3: '' },
    option_exercise_style: '',
    fixing_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    fixing_source: '',
    client_trader_name: '',
    market_maker_trader_name: ''
  })

  const disabled = () => {
    const disabledField = control => {
      if (['string', 'number'].includes(typeof control)) {
        return !control
      } else if (typeof control === 'object') {
        return !control.name
      }
    }
    if (form.deal_type === 'Spot') {
      const deal = new DealSpot(form)
      return Object.values(deal).some(disabledField)
    } else if (form.deal_type === 'Vanilla Option' && page === false) {
      const deal = new ClientDealVanillaForm(form)
      return Object.values(deal).some(disabledField)
    } else if (form.deal_type === 'Vanilla Option' && page === true) {
      const deal = new DealVanilla(form)
      return Object.values(deal).some(disabledField)
    }
  }

  const submitForm = () => {
    if (form.deal_type === DEAL_TYPE.SPOT) {
      const deal = new DealSpot(form)
      webSocketWorkerEvent.sendEvent({
        type: 'create_deal',
        data: deal
      })
    } else if (form.deal_type === DEAL_TYPE.VANILLA_OPTION && page === true) {
      const deal = new DealVanilla(form)
      webSocketWorkerEvent.sendEvent({
        type: 'create_deal',
        data: deal
      })
    } else if (form.deal_type === DEAL_TYPE.VANILLA_OPTION && page === false) {
      setPage(true)
    }
  }

  const handelBackButtonVanillaDeal = () => {
    setPage(false)
  }

  const handelFormChange = (event, element) => {
    const copyForm = { ...form }
    const { name, value } = event.target
    if (
      [
        'base_currency',
        'counter_currency',
        'client',
        'market_maker',
        'trade_origin',
        'cut',
        'fixing_source',
        'client_premium_currency',
        'market_maker_premium_currency',
        'client_option_payment_currency',
        'market_maker_option_payment_currency',
        'client_trader_name',
        'market_maker_trader_name'
      ].includes(name)
    ) {
      copyForm[name] = element
    } else if (
      [
        'quantity',
        'strike',
        'client_price',
        'market_maker_price',
        'client_premium_amount',
        'market_maker_premium_amount'
      ].includes(name) &&
      ((!isNaN(value) && +value > 0) || value === '')
    ) {
      copyForm[name] = Number(value)
    } else if (['deal_type', 'put_call', 'option_exercise_style', 'client_side'].includes(name)) {
      if (value === 'Spot' && page === true) {
        setPage(0)
      }
      copyForm[name] = value
    }
    setForm(copyForm)
  }

  const handelDateChange = (name, value) => {
    const copyForm = { ...form }
    if (
      [
        'trade_date',
        'client_value_date',
        'market_maker_value_date',
        'client_premium_date',
        'market_maker_premium_date',
        'settlement_date',
        'expiry',
        'fixing_date'
      ].includes(name)
    ) {
      copyForm[name] = value
      setForm(copyForm)
    }
  }

  const getContinueButtonTitle = () => {
    switch (form.deal_type) {
      case DEAL_TYPE.SPOT:
        return 'Create Deal'

      case DEAL_TYPE.VANILLA_OPTION:
        if (page === false) {
          return 'Market Side >'
        }
        if (page === true) {
          return 'Create Deal'
        }
        break
    }
  }

  if (checkSomeLoader(loaderList) && loaderFirstTime) {
    loaderFirstTime = false
    return <Loader />
  }
  return (
    <ModalContainer>
      <Container container>
        <SubContainer item sx={3}>
          <Box width={'100%'}>
            <Label>Deal Type *</Label>
            {Array.isArray(dealTypes.data) && (
              <SelectWithArrow
                name="deal_type"
                placeholder="Select Deal Type"
                orderType={form.deal_type}
                onChange={handelFormChange}
                items={dealTypes.data}
              />
            )}
          </Box>
          {['Spot'].includes(form.deal_type) && (
            <SpotDealFirstColumn
              currencyData={currencyData}
              currencyInitial={currencyInitial}
              form={form}
              handelFormChange={handelFormChange}
              clients={clients}
            />
          )}
          {['Vanilla Option'].includes(form.deal_type) && page === false && (
            <VanillaDealFirstColumnClientSide
              clients={clients}
              form={form}
              traders={traders}
              handelDateChange={handelDateChange}
              handelFormChange={handelFormChange}
              currencyData={currencyData}
              currencyInitial={currencyInitial}
            />
          )}
          {['Vanilla Option'].includes(form.deal_type) && page === true && (
            <VanillaDealFirstColumnMarketMakerSide
              marketMakers={marketMakers}
              form={form}
              traders={traders}
              handelDateChange={handelDateChange}
              handelFormChange={handelFormChange}
              currencyData={currencyData}
              currencyInitial={currencyInitial}
            />
          )}
        </SubContainer>
        {['Spot'].includes(form.deal_type) && (
          <SpotDealSecondColumn
            currencyData={currencyData}
            currencyInitial={currencyInitial}
            form={form}
            handelFormChange={handelFormChange}
            marketMakers={marketMakers}
            sides={sides}
          />
        )}
        {['Vanilla Option'].includes(form.deal_type) && page === true && (
          <VanillaDealSecondColumnMarketMakerSide
            clients={clients}
            form={form}
            sides={sides}
            traders={traders}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
          />
        )}

        {['Vanilla Option'].includes(form.deal_type) && page === false && (
          <VanillaDealSecondColumnClientSide
            clients={clients}
            form={form}
            sides={sides}
            traders={traders}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
          />
        )}

        {['Vanilla Option'].includes(form.deal_type) && page === false && (
          <VanillaDealThirdColumnClientSide
            putCall={[{ name: 'PUT' }, { name: 'CALL' }]}
            cut={cut}
            fixingSource={fixingSource}
            form={form}
            sides={sides}
            traders={traders}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
          />
        )}

        {['Vanilla Option'].includes(form.deal_type) && page === true && (
          <VanillaDealThirdColumnMarketMakerSide
            putCall={[{ name: 'PUT' }, { name: 'CALL' }]}
            cut={cut}
            fixingSource={fixingSource}
            form={form}
            sides={sides}
            traders={traders}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
          />
        )}
      </Container>

      <Box>
        {['Spot', 'Vanilla Option'].includes(form.deal_type) && page === false && (
          <ModalBottom
            data={data}
            title={getContinueButtonTitle()}
            submitForm={submitForm}
            disabled={disabled()}
            justifyContent="space-between"
          />
        )}
        {['Vanilla Option'].includes(form.deal_type) && page === true && (
          <ModalBottomVanillaDeal
            data={data}
            title={getContinueButtonTitle()}
            submitForm={submitForm}
            disabled={disabled()}
            handelClick={handelBackButtonVanillaDeal}
            justifyContent="space-between"
          />
        )}
      </Box>
    </ModalContainer>
  )
}

export default Deal
