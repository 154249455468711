import { Stack, Box } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import CurrencyPairCell from '../CurrencyPairCell/CurrencyPairCell'
import {
  TableCellStyle,
  TypographyStyle,
  CheckboxTradeBlotter,
  TextQuantity
} from '../../TradeBlotter.style'
import { numberFormat } from '../../../../helper'

const CollapseData = ({
  data,
  dealTradesGroup,
  handelTradeDeal,
  handelTradeDealChecked,
  params
}) => {
  const [trades, setTrades] = useState([])

  useEffect(() => {
    setTrades(handelTradesCollapse())
  }, [dealTradesGroup])

  const onRowClick = trade => {
    if (!(trade.id in dealTradesGroup)) {
      handelTradeDeal(trade)
    }
  }

  const handelGroupCsv = async (trade, dealId) => {
    if (trade.prime_broker_group.startsWith('RBS')) {
      params.current = { prime_broker: 'RBS' }
    } else if (trade.prime_broker_group.startsWith('HSBC')) {
      params.current = { prime_broker: 'HSBC' }
    }
  }

  const handelTradesCollapse = () => {
    return data.trades.map(trade => {
      return {
        id: (
          <TableCellStyle>
            <CheckboxTradeBlotter
              checked={handelTradeDealChecked(trade)}
              onChange={() => handelTradeDeal(trade)}
            />
            <TypographyStyle style={{ marginLeft: '75px' }}>{trade.id}</TypographyStyle>
          </TableCellStyle>
        ),
        type: (
          <TableCellStyle>
            <TypographyStyle>{trade.type}</TypographyStyle>
          </TableCellStyle>
        ),
        client: (
          <TableCellStyle>
            <TypographyStyle>{trade.client}</TypographyStyle>
          </TableCellStyle>
        ),
        market_maker: (
          <TableCellStyle>
            <TypographyStyle>{trade.market_maker}</TypographyStyle>
          </TableCellStyle>
        ),
        side: (
          <TableCellStyle>
            <TypographyStyle>{trade.side === 'BUY' ? 'SELL' : 'BUY'}</TypographyStyle>
          </TableCellStyle>
        ),
        client_quantity: (
          <TableCellStyle>
            <TextQuantity quantity={trade.client_quantity}>
              {numberFormat(trade.client_quantity)}
            </TextQuantity>
          </TableCellStyle>
        ),
        market_maker_quantity: (
          <TableCellStyle>
            <TextQuantity quantity={trade.market_maker_quantity}>
              {numberFormat(trade.market_maker_quantity)}
            </TextQuantity>
          </TableCellStyle>
        ),
        symbol: (
          <TableCellStyle>
            <CurrencyPairCell
              base_currency={data.base_currency}
              base_currency_country={data.base_currency_country}
              counter_currency={data.counter_currency}
              counter_currency_country={data.counter_currency_country}
            />
          </TableCellStyle>
        ),
        premium: (
          <TableCellStyle>
            <TypographyStyle>{trade.premium}</TypographyStyle>
          </TableCellStyle>
        ),
        premium_currency: (
          <TableCellStyle>
            <TypographyStyle>{trade.premium_currency}</TypographyStyle>
          </TableCellStyle>
        ),
        premium_date: (
          <TableCellStyle>
            <TypographyStyle>{trade.premium_date}</TypographyStyle>
          </TableCellStyle>
        ),
        client_price: (
          <TableCellStyle>
            <TypographyStyle>{numberFormat(trade.client_price, 6)}</TypographyStyle>
          </TableCellStyle>
        ),
        market_maker_price: (
          <TableCellStyle>
            <TypographyStyle>{numberFormat(trade.market_maker_price, 6)}</TypographyStyle>
          </TableCellStyle>
        ),
        pnl: (
          <TableCellStyle>
            <TypographyStyle></TypographyStyle>
          </TableCellStyle>
        ),
        trade_data: (
          <TableCellStyle>
            <TypographyStyle>{trade.trade_date}</TypographyStyle>
          </TableCellStyle>
        ),
        value_date: (
          <TableCellStyle>
            <TypographyStyle>{trade.value_date}</TypographyStyle>
          </TableCellStyle>
        ),
        expiry: (
          <TableCellStyle>
            <TypographyStyle>{trade.expiry}</TypographyStyle>
          </TableCellStyle>
        ),
        settlement_date: (
          <TableCellStyle>
            <TypographyStyle>{trade.settlement_date}</TypographyStyle>
          </TableCellStyle>
        ),
        cut: (
          <TableCellStyle>
            <TypographyStyle>{trade.cut}</TypographyStyle>
          </TableCellStyle>
        ),
        prime_broker: (
          <TableCellStyle>
            <TypographyStyle>{trade.prime_broker}</TypographyStyle>
          </TableCellStyle>
        ),
        put_call: (
          <TableCellStyle>
            <TypographyStyle>{trade.put_call}</TypographyStyle>
          </TableCellStyle>
        ),
        direction: (
          <TableCellStyle>
            <TypographyStyle>{trade.direction}</TypographyStyle>
          </TableCellStyle>
        )
      }
    })
  }

  useEffect(() => {
    const agScrollBar = document.body.getElementsByClassName(
      'ag-body-horizontal-scroll-viewport'
    )[0]

    const handelScrollBar = () => {
      const collapses = document.body.getElementsByClassName('collapse-container')
      let collapseWidth = collapses[0].parentElement.clientWidth
      for (const collapse of collapses) {
        collapse.style.width = `${collapseWidth}px`
        collapse.scrollLeft = `${agScrollBar.scrollLeft}`
      }
    }

    if (agScrollBar) {
      agScrollBar.addEventListener('scroll', handelScrollBar)
    }
    return () => {
      agScrollBar.removeEventListener('scroll', handelScrollBar)
      params.current = { prime_broker: '' }
    }
  }, [])

  return (
    <Stack
      style={{
        backgroundColor: '#242428',
        position: 'fixed',
        width: 'max-content',
        overflow: 'auto'
      }}
      className="collapse-container"
    >
      {trades.map((trade, index) => (
        <Box
          key={index}
          style={{
            display: 'flex',
            height: '48px',
            justifyContent: 'space-around',
            alignItems: 'center',
            position: 'relative'
          }}
          onContextMenu={() => handelGroupCsv(data.trades[index], data.id)}
          onClick={() => onRowClick(data.trades[index])}
        >
          {Object.entries(trade).map(([key, value], index2) => (
            <React.Fragment key={index2}>{value}</React.Fragment>
          ))}
        </Box>
      ))}
    </Stack>
  )
}

export default CollapseData

CollapseData.propTypes = {
  data: PropTypes.any,
  dealTradesGroup: PropTypes.object,
  handelTradeDeal: PropTypes.func,
  handelTradeDealChecked: PropTypes.func,
  params: PropTypes.object
}
