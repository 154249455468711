import { combineReducers } from '@reduxjs/toolkit'
import modalReducer from './slices/Modal/modalSlice'
import notificationReducer from './slices/Notification/notificationSlice'
import authReducer from './slices/Auth/AuthSlice'
import bloombergReducer from './slices/Bloomberg/bloombergSlice'
import widgetsSlice from "./slices/widgets/WidgetsSlice"

const appReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  bloomberg: bloombergReducer,
  notification: notificationReducer,
  widgets:widgetsSlice
})

export default appReducer
