import React from 'react'
import PropTypes from 'prop-types'
import { STATUS_CURRENCY_PAIR } from '../../../../utils/constants'
import { ReactComponent as PriceDownIcon } from '../../../../assets/price_down.svg'
import { ReactComponent as PriceUpIcon } from '../../../../assets/price_up.svg'

const PriceIcon = ({ status }) => {
  switch (status) {
    case STATUS_CURRENCY_PAIR.UP:
      return <PriceUpIcon />
    case STATUS_CURRENCY_PAIR.DOWN:
      return <PriceDownIcon />
    case STATUS_CURRENCY_PAIR.SOME:
    default:
      return <></>
  }
}

PriceIcon.propTypes = {
  status: PropTypes.string
}
export default PriceIcon
