import { styled } from '@mui/system'
import { Select, MenuItem } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export const StyledSelect = styled(Select)(({ theme }) => ({
  color: `${theme.palette.text.primary} !important`,
  width: '100%',
  '& .MuiPaper-root': {
    backgroundColor: `${theme.palette.separators.primary} !important`,
    hight: '150px',
    '& ul': {
      hight: '150px',
      backgroundColor: `${theme.palette.separators.primary} !important`
    }
  }
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: 'white',
  display: 'block',
  fontFamily: 'Nunito',
  textTransform: 'capitalize',
  backgroundColor: theme.palette.separators.primary,
  '&:hover': {
    backgroundColor: theme.palette.modal.primary
  }
}))

export const StyledSelectPlaceHolder = styled('div')(({ theme, disabled }) => {
  let backgroundColor = 'transparent'
  if (disabled === 'false') {
    backgroundColor = theme.palette.separators.primary
  }
  return {
    color: 'grey',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: '650',
    backgroundColor
  }
})

export const StyledSelectArrowIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
  color: `${theme.palette.button.save.primary} !important`,
  marginRight: '2px'
}))
